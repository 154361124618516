// src/App.js
import React from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Services from './components/Services';
// import Booking from './components/Booking';
import './App.css';
import About from './components/About';
import ContactInfo from './components/ContactInfo';
import PriceList from './components/PriceList';

const App = () => {
  return (
    <div className="App">
      <Navbar />
      <div className="main">
        <div className="container" id="home"><Home /></div>
        <div className="container" id="about"><About /></div>
        <div className="container" id="contact"><ContactInfo /></div>
        <div className="container" id="services"><Services /></div>
        <div className="container" id="pricelist"><PriceList /></div>
      </div>
    </div>
  );
};

export default App;