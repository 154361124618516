import React from 'react';
import './ContactInfo.css';

const ContactInfo = () => {
    const workingHours = {
        Monday: '10:00 AM - 5:30 PM',
        Tuesday: '10:00 AM - 5:30 PM',
        Wednesday: '10:00 AM - 5:30 PM',
        Thursday: '10:00 AM - 7:30 PM',
        Friday: '10:00 AM - 5:30 PM',
        Saturday: '9:00 AM - 6:00 PM',
        Sunday: '10:00 AM - 6:00 PM',
      };

    return (
        <div className="contact-info-container">
            <h2>Contact and hours</h2>
            <ul className="contact-info-list">
                <li>
                    <strong>Contact Us</strong> 
                    <p><b>Email:</b> <a href="mailto:browsmartau@gmail.com">browsmartau@gmail.com</a></p>
                    <p><b>Phone:</b> <a href="tel:0414979924">0414-979-924</a></p>
                    <p><strong>Address:</strong></p>
                    <p>Birkenhead Point Mall</p>
                    <p>Level (Mezzanine, next to Boost Juice)</p>
                    <p>19 Roseby Street,</p>
                    <p>Drummoyne NSW 2047</p>
                </li>
                <li>
                    <strong>Hours</strong>
                      {Object.keys(workingHours).map((day) => (
                        <p key={day}>
                          <span className="day">{day}: </span>
                          <span className="hours">{workingHours[day]}</span>
                        </p>
                      ))}
                </li>
            </ul>
        </div>
    );
};

export default ContactInfo;
