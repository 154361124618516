// src/components/Carousel.js
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Carousel.css';

const CarouselComponent = () => {
  return (
    <Carousel showThumbs={false} autoPlay infiniteLoop>
      <div>
        <img src="/eyebrows.jpeg" alt="eyebrows" />
      </div>
      <div>
        <img src="/eyebrows.webp" alt="eyebrows" />
      </div>
      <div>
        <img src="/lashes_service.png" alt="eyelashes_service" />
      </div>
      <div>
        <img src="/threading_service.webp" alt="threading_service" />
      </div>
    </Carousel>
  );
};

export default CarouselComponent;
