import React from 'react';
import './About.css';

const About = () => {
    return (
        <div className="about-us-container">
            <h2>Discover Browsmart!</h2>
            <p>
            Welcome to BrowSmart – the ultimate destination for flawless brows and luxurious lashes. Our skilled professionals use precision and expertise to elevate your natural beauty. At BrowSmart, we offer a comprehensive range of services, including eyebrow shaping, threading, tinting, lash extensions, and more. Step into BrowSmart and let us transform your beauty routine.
            </p>
        </div>
    );
};

export default About;
