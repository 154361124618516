import React from 'react';
import './Navbar.css';

const Navbar = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <nav className="navbar">
      <h1>Browsmart</h1>
      <ul>
        <li><button onClick={() => scrollToSection("home")}>Home</button></li>
        <li><button onClick={() => scrollToSection("about")}>About</button></li>
        <li><button onClick={() => scrollToSection("contact")}>Contact</button></li>
        <li><button onClick={() => scrollToSection("services")}>Services</button></li>
        <li><button onClick={() => scrollToSection("pricelist")}>Prices</button></li>
      </ul>
    </nav>
  );
};

export default Navbar;
