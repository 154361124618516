import React from 'react';
import './Services.css';

const Services = () => {
    return (
        <div className="services-container">
            <h2>Our Services</h2>
            <ul className="services-list">
                <li>
                    <strong>Eyebrow Shaping:</strong> 
                    Achieve perfectly sculpted brows that complement your facial features.
                </li>
                <li>
                    <strong>Eyebrow Threading:</strong> 
                    Experience precise and gentle hair removal for beautifully defined brows.
                </li>
                <li>
                    <strong>Eyebrow Waxing:</strong> 
                    Achieve perfectly shaped brows with our precise waxing services.
                </li>
                <li>
                    <strong>Tinting:</strong> 
                    Enhance the color and fullness of your brows and lashes with our professional tinting services.
                </li>
                <li>
                    <strong>Lash Extensions:</strong> 
                    Transform your look with our premium lash extensions, adding volume and length for stunning eyes.
                </li>
                <li>
                    <strong>Brow Lamination:</strong> 
                    Get fuller, more defined brows with our innovative brow lamination technique.
                </li>
                <li>
                    <strong>Henna & Hybrid Tint:</strong> 
                    Enjoy long-lasting color and definition with our henna and hybrid tinting options.
                </li>
                <li>
                    <strong>Lash Lift:</strong> 
                    Lift and curl your natural lashes for a wide-eyed, glamorous look.
                </li>
            </ul>
        </div>
    );
};

export default Services;
