import React from 'react';
import './PriceList.css';

const priceList = {
  'Threading | Waxing': [
    { service: 'Brow Shape', price: '$28' },
    { service: 'Top Lip', price: '$19' },
    { service: 'Chin', price: '$19' },
    { service: 'Side Burn', price: '$21' },
    { service: 'Cheek', price: '$15' },
    { service: 'Forehead', price: '$15' },
    { service: 'Neck', price: '$21' },
    { service: 'Nostril Wax', price: '$23' },
    { service: 'Ear Wax', price: '$23' },
    { service: 'Full Face', price: '$85' },
  ],
  'Packages': [
    { service: 'Lip + Chin', price: '$35' },
    { service: 'Brow Shape + Lip', price: '$44' },
    { service: 'Brow Shape + Lip + Chin', price: '$62' },
    { service: 'Brow Shape + Lip + Brow Tint', price: '$58' },
    { service: 'Brow Shape + Brow Tint', price: '$51' },
    { service: 'Brow Shape + Hybrid Brow Tint', price: '$63' },
    { service: 'Brow Shape + Henna Brow Tint', price: '$67' },
    { service: 'Brow Shape + Brow & Lash Tint (most popular)', price: '$73' },
    { service: 'Full Face + Brow Tint', price: '$103' },
  ],
  'Tinting': [
    { service: 'Brow Tint', price: '$25' },
    { service: 'Lash Tint', price: '$25' },
    { service: 'Brow Tint + Lash Tint', price: '$45' },
  ],
  'Lash Lift | Perm': [
    { service: 'Lash Lift', price: '$80' },
    { service: 'Lash Lift + Tint', price: '$99' },
  ],
  'Henna/Hybrid Tint': [
    { service: 'Henna Brow Tint', price: '$43' },
    { service: 'Hybrid Brow Tint', price: '$39' },
  ],
  'Brow Lamination': [
    { service: 'Brow Lamination', price: '$81' },
    { service: 'Brow Lamination + Brow Tint', price: '$95' },
    { service: 'Brow Lamination + Hybrid Tint', price: '$110' },
  ],
  'Extensions': [
    { service: 'Lash Extension Classic', price: '$150 & up' },
    { service: 'Lash Extension Hybrid', price: '$175 & up' },
    { service: 'Refills', price: '$50 & up' },
  ],
  "Men's Services": [
    { service: 'Brow Shape', price: '$30' },
    { service: 'Brow Tint', price: '$25' },
    { service: 'Hybrid Brow Tint', price: '$37' },
    { service: 'Lash Tint', price: '$27' },
  ],
};

const PriceList = () => {
  return (
    <div className="price-list-container">
      <h2>Price List </h2>
      {Object.entries(priceList).map(([category, services]) => (
        <div key={category} className="price-card">
          <h2>{category}</h2>
          <ul>
            {services.map(({ service, price }) => (
              <li key={service} className="service-item">
                <span>{service}</span>
                <span>{price}</span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default PriceList;
